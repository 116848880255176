import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ICellRendererParams } from '@ag-grid-community/core';
import { DateTimeDisplayStyle, FormattedDateTime } from '../../components/DataDisplay/FormattedDateTime';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    })
);

interface CellProps extends ICellRendererParams {}

// tslint:disable-next-line: no-any
export const DueDateRenderer: React.FC<CellProps> = (props) => {
    const classes = useStyles();

    const isDateDue = (value: Date) => {
        const currentDate = moment();
        const diff = moment(value).diff(currentDate, 'days');

        return diff < 0;
    };
    
    if (props.value === undefined || props.value === null) {
        return <div />;
    }
    
    return (
        <div className={classes.root} style={{ color: isDateDue(props.value) ? 'red' : 'inherit' }}>
            <FormattedDateTime
                value={props.value} 
                displayStyle={DateTimeDisplayStyle.ShortDate} 
                DisplayInUTC={false}                        
            />
        </div>
    );
};
