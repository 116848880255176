/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, Button, Dialog, DialogTitle, DialogContent, Typography, Chip, Divider } from '@material-ui/core';
import { mainTheme } from '../../Theme';
import { showNotification } from '../../App';
import { DocumentFile } from '../../types/DocumentFile';
import { retrieveTaskDetailsData, TaskDetailsData, TaskDetailsParam } from '../../tasksSummary/TasksSummaryRepository';
import { RvLoader } from '../../components/Loader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '20px 0',
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            overflowY: 'auto',
            marginBottom: '10px',
            // maxWidth: '500px',
            position: 'relative',
        },
        historyContainer: {
            display: 'flex',
            alignItems: 'center',
            margin: '0 -10px',
            width: '100%',
            padding: '10px 0',
            position: 'relative',
            '& > div': {
                padding: '0 10px',
            },
            '& .connector': {
                borderLeft: '1px solid #bdbdbd',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: '30px',
            }
        },
        historyIcon: {
            
        },
        taskDetails: {
            // flex: 1,
            // maxWidth: '150px',
            minWidth: '130px',
            minHeight: '100%',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            '& .description': {
                fontWeight: 600,
                marginBottom: '3px',
                color: mainTheme.BrandColors.TemplatePrimary
            },
            '& .date': {
                fontStyle: 'italic',
                color: '#a7a7a7'
            },
            '& .due-date': {
                color: '#fcba03'
            },
            '& .overdue': {
                color: 'red',
            },
            '& .status': {
                fontStyle: 'italic',
                color: '#a7a7a7'
            }
        },
        createClient: {
            // backgroundColor: mainTheme.UtilityColors.Positive
            backgroundColor: '#cfa2a1'
        },
        assignedEnquiry: {
            // backgroundColor: mainTheme.UtilityColors.Neutral
            backgroundColor: '#a8dda8'
        },
        followUp: {
            // backgroundColor: mainTheme.UtilityColors.Negative
            backgroundColor: '#bdb2d1'
        },
        enquiryCreated: {
            backgroundColor: 'orange'
        },
        contentDetails: {
            flex: 1,
            '& .title': {
                fontSize: 14,
                fontWeight: 300,
                color: '#000',
                marginBottom: 5,
            },
            '& .description': {
                marginTop: 10,
                fontSize: 14,
                color: '#000'
            },
            '& .author': {
                fontStyle: 'italic',
                fontSize: 12,
                '& span': {
                    fontWeight: 600
                }
            }
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            marginLeft: '8px'
        },
        detailsContainer: {
            padding: '10px !important',
            width: '100%',
            position: 'relative',
            // '& span': {
            //     fontWeight: 600,
            //     fontStyle: 'italic'
            // }
        },
        dialogRoot: {
            '& .downshift-wrapper': {
                flex: 1,
                padding: 0,
            }
        },
        header: {
            textTransform: 'capitalize',
            '& .MuiTypography-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }
        },
        dialogPaper: {
            height: '100%',
        },
        emailContentButton: {
            textAlign: 'end',
            position: 'absolute',
            right: 10,
            top: 10,
        },
        emailDetails: {
            textAlign: 'start',
            paddingRight: 80,
            '& .emailDescription': {
                color: '#000',
                fontSize: 14
            }
        },
        divider: {
            margin: 10,
            width: '100%',
        },
        loaderWrapper: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    })
);

interface EmailContentDialogProps {
    className?: string;
    enquiryGuid?: string;
    taskGuid?: string;
    onCloseEmailContent?: () => void;
}

interface EmailContentDialogState {
    showEmailContent: boolean;
    emailContent: string;
    files: DocumentFile[];
    isLoading: boolean;
}

// tslint:disable-next-line: no-anyW
export const EmailContentDialog: React.FC<EmailContentDialogProps> = props => {
    
    const classes = useStyles();

    const [state, setState] = useState<EmailContentDialogState>({
        showEmailContent: true,
        emailContent: '',
        files: [],
        isLoading: true
    });

    useEffect(() => {
        if (props.taskGuid) {
            fetchTaskDetails(props.taskGuid);
        }
    // tslint:disable-next-line: align
    }, []);

    const fetchTaskDetails = (guid: string) => {
        const taskDetailsParams: TaskDetailsParam = {
            guid: guid
        };

        retrieveTaskDetailsData(
            taskDetailsParams,
            true,
            // tslint:disable-next-line: no-console
            (data: TaskDetailsData) => onTaskDetailsRetrieved(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');

                setState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false
                    };
                });
            }
        );
    };

    const onTaskDetailsRetrieved = (data: TaskDetailsData) => {
        // tslint:disable-next-line: no-console

        if (data && data.task && data.task.detail) {
            const taskDetails = data.task.detail;

            setState((prevState) => {
                return {
                    ...prevState,
                    isLoading: false,
                    files: taskDetails.files ? taskDetails.files : [],
                    emailContent: taskDetails.emailContent
                };
            });
        } else {
            setState((prevState) => {
                return {
                    ...prevState,
                    isLoading: false
                };
            });
        }
    };

    const onCloseEmailContent = () => {
        setState((prevState) => {
            return {
                ...prevState,
                emailContent: '',
                showEmailContent: false,
                files: [],
                isLoading: false
            };
        });

        if (props.onCloseEmailContent) {
            props.onCloseEmailContent();
        }        
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getFileItems = (files?: DocumentFile[]) => {
        if (files) {
            const filteredFiles = files.filter((file: DocumentFile) => {
                return file.status !== 2 && file.url;
            });

            if (filteredFiles) {
                const chip = (
                    <div>
                        {
                            filteredFiles.length > 0 
                            ? (
                                <>
                                    <Typography variant="h6">{'Attachments'}</Typography> 
                                    <Typography>{'Click on the following to open the attached files'}</Typography> 
                                </>
                            )
                            : null
                        }
                        <div className={classes.chips}>
                            {
                                filteredFiles.map((file: DocumentFile, index: number) => {
                                    return (
                                        <Chip
                                            key={index}
                                            className={classes.chip}
                                            label={file.name} 
                                            onClick={() => onFileClicked(file)}
                                        />
                                    );
                                })  
                            }
                        </div>
                        {filteredFiles.length > 0 && (
                            <Divider className={classes.divider} />
                        )}                        
                    </div>                
                );

                return chip;
            } else {
                return (<div/>);
            }
        } else {
            return (<div/>);
        }
    };

    const onFileClicked = (file: DocumentFile) => {
        if (file && file.url) {
            var win = window.open(file.url, '_blank');
            if (win) {
                win.focus();
            }            
        }
    };

    const getLoader = () => {
        return (
            <div className={classes.loaderWrapper}>
                <RvLoader />
            </div>
        );
    };

    return (
        <div className={`${classes.root} ${props.className}`}>
            <Dialog
                open={state.showEmailContent}
                onClose={onCloseEmailContent}
                maxWidth="md"
                fullWidth={true}
                className={classes.dialogRoot}
                scroll={'paper'}
                classes={{
                    paper: classes.dialogPaper
                }}                        
            >
                <DialogTitle className={classes.header}>
                    <span>Email Content</span>
                    <Button onClick={onCloseEmailContent} color="primary">
                        Close
                    </Button>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <>
                        {
                            state.isLoading 
                                ? getLoader()
                                : (
                                    <>                                        
                                        {getFileItems(state.files)}                                       
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: state.emailContent
                                            }}
                                        />
                                    </>
                                )
                        }
                        
                    </>                                                        
                </DialogContent>                        
            </Dialog>               
        </div>
    );
};
