import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { SemanticCOLORS } from 'semantic-ui-react';
import { GroupByType } from '../EnquirySummaryRepository';
import { UserAutocompleteSelector } from '../../components/UserAutocompleteSelector';
import useOnClickOutside from 'use-onclickoutside';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { Avatar } from '@material-ui/core';
import moment from 'moment';
import { AreaOfLawSelector } from '../selectors/AreaOfLawSelector';
// import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined'; 
// import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';
import { EnquirySummary } from '../models/Enquiry';
// import { useHistory } from 'react-router-dom';
// import { EnquirySummaryContext } from '../EnquirySummaryContext';
import { EnquiryTaskAction } from '../EnquiryTaskAction';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      userSelect: 'none',
      padding: 8,
      margin: '0 0 8px 0',
      color: '#000',
      display: 'flex',
      position: 'relative',
    },
    option: {
      position: 'absolute',
      top: 0,
      right: 0
    },
    contentContainer: {
      // padding: '0 10px',
      display: 'flex',
      flexFlow: 'column',
      flex: 1,
    },
    content: {
      paddingBottom: '10px',
      flex: 1,
      '& .posOrg': {
        fontStyle: 'italic',
        color: '#989393',
        fontSize: '12px',
      },
      '& .org': {
        fontWeight: 600,
        color: '#6d6c6c'
      }
    },
    otherDetails: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '12px',
      '& > div': {
        flex: 1,
      },
      '& .session': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      '& .user': {
        backgroundColor: 'yellow',
        padding: '3px 5px',
        textTransform: 'capitalize',
        borderRadius: '3px',
      }
    },
    userContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '12px',
        padding: '10px 0',
        cursor: 'pointer',
        '& .name': {
          fontWeight: 600,
        },
        '& .category': {
          padding: '0 5px',
          fontWeight: 600,
        }
    },
    userSelector: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 5px',
      margin: '0 -5px',
      '& > div': {
        padding: '0 5px',
      }
    },
    avatar: {
      width: '20px',
      height: '20px',
      fontSize: '10px',
    },
    areaOfLaw: {
      cursor: 'pointer'
    },
    status: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      cursor: 'pointer',
    },
    textField: {
      width: '100%',
      minWidth: '150px',
    }
  })
);

export const Colors: SemanticCOLORS[] = ['red', 'orange', 'olive', 'green', 'teal', 'blue',
    'purple', 'pink', 'brown', 'grey'];

export function hashString(input: string): number {
  var hash = 0, i, chr;
  if (input.length === 0) {
      return hash;
  }
  for (i = 0; i < input.length; i++) {
      chr = input.charCodeAt(i);
      // tslint:disable-next-line:no-bitwise
      hash = ((hash << 5) - hash) + chr - 1;
      // tslint:disable-next-line:no-bitwise
      hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

interface BoardCardDraggableProps {
  draggableProvided: DraggableProvided;
  draggableSnapshot: DraggableStateSnapshot;
  // tslint:disable-next-line: no-any
  item: EnquirySummary;
  areaOfLaw: IAutoCompleteItem;
  status: string;
  assignedTo: IAutoCompleteItem;
  dueDate: Date | null;
  groupBy: GroupByType;
  reason: string;
}

interface BoardCardDraggableState {
  groupBy: GroupByType;
  areaOfLaw: IAutoCompleteItem;
  assignedTo: IAutoCompleteItem;
}

export const BoardCardDraggable: React.FC<BoardCardDraggableProps> = props => {

  const classes = useStyles();

  const [state, setState] = useState<BoardCardDraggableState>({
    areaOfLaw: props.areaOfLaw,
    assignedTo: props.assignedTo,
    groupBy: props.groupBy
  });

  // const [open, setOpen] = React.useState(false);
  // const anchorRef = React.useRef<HTMLButtonElement>(null);
  // const history = useHistory();

  useEffect(() => {

    // tslint:disable-next-line: no-console
    // console.log('state', state);

    setState((prevState) => {
      return {
        ...prevState,
        groupBy: props.groupBy
      };
    });

  // tslint:disable-next-line: align
  }, [props.groupBy]);

  const [isAreaOfLawSelected, setIsAreaOfLawSelected] = useState(false);
  const [isAssignedToSelected, setIsAssignedToSelected] = useState(false);

  const refAreaOfLaw = React.useRef(null);

  const refAssignedTo = React.useRef(null);

//   const {
//     onFormAction,
//     onEnquiryFormOpen,
//     onFollowUpOpen,
//     onAssignOpen,
//     onCreateClientOpen,
//     onSetGuid
// // tslint:disable-next-line: no-any
// }: any = useContext(EnquirySummaryContext);

  // click outside hide the selector
  useOnClickOutside(refAreaOfLaw, () => setIsAreaOfLawSelected(false));

  // click outside hide the selector
  useOnClickOutside(refAssignedTo, () => setIsAssignedToSelected(false));

  // const handleToggle = () => {
  //   // tslint:disable-next-line: no-shadowed-variable
  //   setOpen(prevOpen => !prevOpen);
  // };

  const avatar = () => {

    let bColor: SemanticCOLORS = Colors[(state.assignedTo.label === '' || state.assignedTo.label === null) ? 
    Colors[Colors.length] : (hashString(state.assignedTo.label) % Colors.length) - 1];
    // tslint:disable-next-line: no-any
    let stringSplit = state.assignedTo.label.split(' ').map((n: any) => n[0]).join('');

    let stringInitials = stringSplit.slice(0, 2);

    return (
      <Avatar 
        className={classes.avatar}
        style={{ backgroundColor: bColor ? bColor : 'grey', color: '#fff' }}
      >
        {stringInitials}
      </Avatar>
    );

  };

  // .endOf('day').fromNow()
  const dueDate = () => {
    return `Due ${moment(props.dueDate, 'YYYY-MM-DD HH:mm:ss').fromNow()}`;  
  };

  const handleClickAreaOfLaw = () => {
    setIsAreaOfLawSelected(true);
  };

  const handleClickAssignedTo = () => {
    setIsAssignedToSelected(true);
  };

  const handleChangeAreaOfLaw = (data: IAutoCompleteItem, name: string) => {

    setState((prevState) => {
      return {
        ...prevState,
        areaOfLaw: data
      };
    });

  };

  const handleChangeAssignedTo = (data: IAutoCompleteItem, name: string) => {

    setState((prevState) => {
      return {
        ...prevState,
        assignedTo: data
      };
    });

  };

  // const handleClose = (event: React.MouseEvent<EventTarget>) => {
  //   // tslint:disable-next-line
  //   console.log('Close Clicked');
  //   if (anchorRef.current &&
  //       anchorRef.current.contains(event.target as HTMLElement)
  //   ) {
  //       return;
  //   }

  //   setOpen(false);
  // };

  // const handleView = (event: React.MouseEvent<EventTarget>) => {
  //   if (
  //       anchorRef.current &&
  //       anchorRef.current.contains(event.target as HTMLElement)
  //   ) {
  //       return;
  //   }
  //   setOpen(false);
  //   history.push({
  //       pathname: `/enquiriesDetail/${props.item.guid}`
  //   });
  // };

  // const handleListKeyDown = (event: React.KeyboardEvent) => {
  //   if (event.key === 'Tab') {
  //     event.preventDefault();
  //     setOpen(false);
  //   }
  // };

  // const handleFollowUp = (event: React.MouseEvent<EventTarget>) => {
  //   if (anchorRef.current &&
  //       anchorRef.current.contains(event.target as HTMLElement)
  //   ) {
  //       return;
  //   }
  //   onSetGuid(props.item.guid);
  //   setOpen(false);
  //   onFollowUpOpen();
  // };

  // const handleCreateClient = (event: React.MouseEvent<EventTarget>) => {
  //   if (
  //       anchorRef.current &&
  //       anchorRef.current.contains(event.target as HTMLElement)
  //   ) {
  //       return;
  //   }
  //   onSetGuid(props.item.guid);
  //   setOpen(false);
  //   onCreateClientOpen(props.item);
  // };

  // const handleModify = (event: React.MouseEvent<EventTarget>, guid: string) => {
  //   if (anchorRef.current &&
  //       anchorRef.current.contains(event.target as HTMLElement)) {
  //       return;
  //   }

  //   onFormAction('edit', guid);
  //   setOpen(false);
  //   onEnquiryFormOpen();
  // };

  // const handleAssign = (event: React.MouseEvent<EventTarget>) => {
  //   if (
  //       anchorRef.current &&
  //       anchorRef.current.contains(event.target as HTMLElement)
  //   ) {
  //       return;
  //   }

  //   onSetGuid(props.item.guid);
  //   setOpen(false);
  //   onAssignOpen();
  // };

  // const handleEmailAssignee = (event: React.MouseEvent<EventTarget>) => {
  //   if (
  //       anchorRef.current &&
  //       anchorRef.current.contains(event.target as HTMLElement)
  //   ) {
  //       return;
  //   }
  //   setOpen(false);

  //   var mail = document.createElement('a');
  //   // tslint:disable-next-line:max-line-length
  //   mail.href = `mailto:${props.item.assignedToEmailAddress}?subject=
  // RE ${props.item.firstName} ${props.item.lastName}, new business enquiry received 
  // ${moment(props.item.initialEnquiryDate).format('DD MMM YYYY')}`;
  //   mail.click();

  // };

  const isOverDue = () => {
    return moment().diff(props.dueDate, 'days');
  };

  const formattedDate = (date: Date | null, format: string) => {
      if (date) {
        return moment(date).format(format);
      }
      return undefined;
  };

  return (
    <div 
      className={classes.root}
      key={Math.random()}
      ref={props.draggableProvided.innerRef}
      {...props.draggableProvided.draggableProps}
      {...props.draggableProvided.dragHandleProps}
      style={{
        backgroundColor: props.draggableSnapshot.isDragging ? '#fff' : '#fff',
        ...props.draggableProvided.draggableProps.style
      }}
    >
      <div className={classes.option}>
        <EnquiryTaskAction 
            enquiryGuid={props.item.guid}
            data={props.item}
        />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          {props.item.name && (<div>{props.item.name}</div>)}
          {props.item.positionAtOrganisation && (<div className="posOrg">{props.item.positionAtOrganisation}</div>)}
          {props.item.organisation && (<div className="org">{props.item.organisation}</div>)}
        </div>
        <div className={classes.userContainer}>
          {!!(state.groupBy !== GroupByType.areaOfLaw) && (
            <div ref={refAreaOfLaw} onClick={handleClickAreaOfLaw}>
              {
                isAreaOfLawSelected ? 
                (
                  <AreaOfLawSelector 
                    key={Math.random()}
                    label="Area Of Law"
                    name="areaOfLaw"
                    value={state.areaOfLaw}
                    onSelection={handleChangeAreaOfLaw}
                    disablePortal={true}
                    className={classes.textField}
                  />
                ) 
                : 
                (
                  <div>{state.areaOfLaw.label}</div>
                )
              }
            </div>
          )}
          {!!(state.groupBy === GroupByType.areaOfLaw) && (
            <div ref={refAssignedTo} onClick={handleClickAssignedTo}>
              {
                isAssignedToSelected ? 
                (
                  <UserAutocompleteSelector 
                    value={state.assignedTo}
                    label="Assigned To"
                    name="assignedTo"
                    onSelection={handleChangeAssignedTo}
                    visible={{
                      role: true
                    }}
                  />
                )
                :
                (
                  <>
                    {
                      state.assignedTo.label && state.assignedTo.value && (
                        <div className={classes.userSelector}>
                          {avatar()}
                          <div className="name">{state.assignedTo.label}</div>
                        </div>
                      )
                    }
                  </>
                )
              }
            </div>
          )}
          {!!(state.groupBy === GroupByType.dueDates) && (
            <div className={classes.areaOfLaw}>
              <div>{props.status}</div>
            </div>
          )}
        </div>
        <div className={classes.otherDetails}>
          {!!(state.groupBy !== GroupByType.status && state.groupBy !== GroupByType.dueDates) && (
            <div className={classes.areaOfLaw}>
              <div>{props.status}</div>
            </div>
          )}
          {!!(state.groupBy === GroupByType.status || state.groupBy === GroupByType.dueDates) && (
            <div ref={refAssignedTo} onClick={handleClickAssignedTo}>
              {
                isAssignedToSelected ? 
                (
                  <UserAutocompleteSelector 
                    value={state.assignedTo}
                    label="Assigned To"
                    name="assignedTo"
                    onSelection={handleChangeAssignedTo}
                    visible={{
                      role: true
                    }}
                  />
                )
                :
                (
                  <>
                    {
                      state.assignedTo.label && state.assignedTo.value && (
                        <div className={classes.userSelector}>
                          {avatar()}
                          <div className="name">{state.assignedTo.label}</div>
                        </div>
                      )
                    }
                  </>
                )
              }
            </div>
          )
          }
          {props.dueDate && props.status !== null && props.status.toLowerCase() !== 'lost' && 
            props.status.toLowerCase() !== 'client created' && props.status.toLowerCase() !== 'matter created' &&
            props.status.toLowerCase() !== 'client already exists' && (
            <div 
              className="session"
              style={{ color: isOverDue() > 0 ? 'red' : '#000'}}
            >
              {dueDate()}
            </div>
          )}
          {props.dueDate && props.status !== null && props.status.toLowerCase() === 'lost' && 
            props.status.toLowerCase() !== 'client created' && props.status.toLowerCase() !== 'matter created' &&
            props.status.toLowerCase() !== 'client already exists' && (
            <div 
              className="session"
              // style={{ color: isOverDue() > 0 ? 'red' : '#000'}}
            >
              {props.reason}
            </div>
          )}
          {props.item.createdDate && props.status !== null && 
           (props.status.toLowerCase() === 'client created' || props.status.toLowerCase() === 'matter created' || props.status.toLowerCase() === 'client already exists') && (
            <div 
              className="session"
              // style={{ color: isOverDue() > 0 ? 'red' : '#000'}}
            >
              {formattedDate(props.item.createdDate, 'DD MMM YYYY')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};