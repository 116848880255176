/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import { makeStyles, Theme, createStyles, Avatar, Tooltip, withStyles, Paper, Button, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import moment from 'moment';
import { mainTheme } from '../../Theme';
import { TaskDetails, TaskSummaryList } from '../../leadsDetail/LeadsDetailRepository';
import { EnquiryDetail } from '../models/Enquiry';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import NotesOutlinedIcon from '@material-ui/icons/NotesOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import NotListedLocationOutlinedIcon from '@material-ui/icons/NotListedLocationOutlined';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import { Colors, hashString } from '../board/BoardCardDraggable';
import NotInterestedOutlinedIcon from '@material-ui/icons/NotInterestedOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
// import { MatterTerminology } from '../../MatterTerminology';
import { formatNumber } from '../../helpers/ChartHelper';
// import { CurrencySymbolData, retrieveCurrencySymbolData } from '../../ClientAndContactDetails/referrals/ReferralsDetailsRepository';
import { EmailContentDialog } from './EmailContentDialog';
import { TimezoneOption, } from '../../lookupConfiguration/selectors/TimezoneSelector';
import LinkOffOutlinedIcon from '@material-ui/icons/LinkOffOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleOutlinedIcon from '@material-ui/icons/RemoveCircleOutlined';
import { green } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import { TranslationTextList } from '../../locales/common';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '20px 0',
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            overflowY: 'auto',
            marginBottom: '10px',
            // maxWidth: '500px',
            position: 'relative',
        },
        loadingRoot: {
            padding: '20px 0',
            flex: 1,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            position: 'relative',
            marginBottom: '10px',
        },
        historyContainer: {
            display: 'flex',
            alignItems: 'center',
            margin: '0 -10px',
            width: '100%',
            padding: '10px 0',
            position: 'relative',
            '& > div': {
                padding: '0 10px',
            },
            '& .connector': {
                borderLeft: '1px solid #bdbdbd',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: '30px',
            }
        },
        historyIcon: {
            
        },
        taskDetails: {
            // flex: 1,
            // maxWidth: '150px',
            minWidth: '130px',
            minHeight: '100%',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            '& .description': {
                fontWeight: 600,
                marginBottom: '3px',
                color: mainTheme.BrandColors.TemplatePrimary
            },
            '& .date': {
                fontStyle: 'italic',
                color: '#a7a7a7'
            },
            '& .due-date': {
                color: '#fcba03'
            },
            '& .overdue': {
                color: 'red',
            },
            '& .status': {
                fontStyle: 'italic',
                color: '#a7a7a7'
            }
        },
        createClient: {
            // backgroundColor: mainTheme.UtilityColors.Positive
            backgroundColor: '#cfa2a1'
        },
        assignedEnquiry: {
            // backgroundColor: mainTheme.UtilityColors.Neutral
            backgroundColor: '#a8dda8'
        },
        followUp: {
            // backgroundColor: mainTheme.UtilityColors.Negative
            backgroundColor: '#bdb2d1'
        },
        enquiryCreated: {
            backgroundColor: 'orange'
        },
        contentDetails: {
            flex: 1,
            '& .title': {
                fontSize: 14,
                fontWeight: 300,
                color: '#000',
                marginBottom: 5,
            },
            '& .description': {
                marginTop: 10,
                fontSize: 14,
                color: '#000',
                maxWidth: '340px',                
            },
            '& .wordBreak': {
                'word-break': 'break-all'              
            },
            '& .author': {
                fontStyle: 'italic',
                fontSize: 12,
                '& span': {
                    fontWeight: 600
                }
            }
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            marginLeft: '8px'
        },
        detailsContainer: {
            padding: '10px !important',
            width: '100%',
            position: 'relative',
            // '& span': {
            //     fontWeight: 600,
            //     fontStyle: 'italic'
            // }            
            '& .due-date': {
                color: '#fcba03'
            },
        },
        dialogRoot: {
            '& .downshift-wrapper': {
                flex: 1,
                padding: 0,
            }
        },
        header: {
            textTransform: 'capitalize',
            '& .MuiTypography-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }
        },
        dialogPaper: {
            height: '100%',
        },
        emailContentButton: {
            textAlign: 'end',
            position: 'absolute',
            right: 10,
            top: 10,
        },
        emailDetails: {
            textAlign: 'start',
            paddingRight: 80,
            '& .emailDescription': {
                color: '#000',
                fontSize: 14
            }
        },
        divider: {
            margin: 10,
            width: '100%',
        },
        notes: {
            backgroundColor: 'rgba(243, 243, 158, 0.29)',
            padding: '10px',
        },
        assignButton: {
            position: 'absolute',
            right: 10,
            bottom: 10,
            textTransform: 'none'
        }
    })
);

export enum HistoryActivityWidth {
    FULL_WIDTH = 0,
    NORMAL
}
interface HistoryActivityProps {
    className?: string;
    guid?: string;
    enquiryDetail: EnquiryDetail;
    // sortColumn: string | null;
    sortDirection: string | null;
    // isRefreshData?: boolean;
    width?: HistoryActivityWidth;
    onCreateClient?: (isCreateClient: boolean) => void;
    autoScroll?: boolean;
    currency: string;
    timezone: TimezoneOption[];
    leadDetailsHistory: TaskSummaryList;
    onAssign?: (task: TaskDetails) => void;
    canCreateMatter: boolean;
}

interface HistoryActivityState {
    showEmailContent: boolean;
    taskGuid?: string | null;
    // isLoading: boolean;
}

// tslint:disable-next-line: no-anyW
export const HistoryActivity: React.FC<HistoryActivityProps> = props => {
    
    const { t } = useTranslation() as {
        t: (key: keyof TranslationTextList) => string;
    };
    
    const classes = useStyles();

    // const [leadDetailsHistory, setLeadDetailsHistory] = useState<TaskSummaryList>({
    //     tasks: [],
    //     actualRowCountForSearch: 0,
    //     recordCount: 0
    // });

    const [state, setState] = useState<HistoryActivityState>({
        showEmailContent: false
        // isLoading: true
    });

    // const [currency, setCurrency] = useState<string>('');
    // const [timezone, setTimezone] = useState<TimezoneOption[]>();

    // tslint:disable-next-line: no-any
    const messagesEndRef = useRef<any>(null);

    const scrollToBottom = () => {
        if (messagesEndRef && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ 
                behavior: 'smooth',
            });
        }
    };

    // useEffect(() => {
        // if (props.guid) {
            // fetchCurrency();
            // fetchLeadDetailsHistory(props.guid);
            // fetchTimezone();
        // }
    // tslint:disable-next-line: align
    // }, [props.isRefreshData, props.guid]);

    // const fetchCurrency = () => {
    //     retrieveCurrencySymbolData(
    //         false,
    //         // tslint:disable-next-line: no-any
    //         (data: any) => onDataCurrency(data),
    //         // tslint:disable-next-line:no-any
    //         function (reason: any): void {
    //           showNotification(null, reason, 'error');
    //     });
    // };

    // const onDataCurrency = (data: CurrencySymbolData) => {
    //     setCurrency(data.options.currencySymbol.symbol);
    // };

    // const fetchTimezone = () => {
    //     retrieveTimezoneOptionData(
    //         false,
    //         // tslint:disable-next-line: no-console
    //         (data) => onRetrieveTimezoneData(data),
    //         // tslint:disable-next-line:no-any
    //         function (reason: any): void {
    //             showNotification(null, reason, 'error');
    //         }
    //     );
    // };

    // const onRetrieveTimezoneData = (data: TimezoneOptionData) => {
    //     setTimezone(data.options.timezoneOption);
    // };
    
    // const fetchLeadDetailsHistory = (guid: string) => {
    //     var leadQueryParams: LeadDetailsHistoryQueryParams = {
    //         offset: null,
    //         first: null,
    //         filter: null,
    //         sortColumn: props.sortColumn,
    //         sortDirection: props.sortDirection,
    //         entityGuidID: guid
    //     };

    //     retrieveLeadDetailsHistoryData(
    //         leadQueryParams,
    //         true,
    //         // tslint:disable-next-line: no-any
    //         (data: any) => onDataRetrieved(data),
    //         // tslint:disable-next-line
    //         function (reason: any): void {
    //             showNotification(null, reason, 'error');
    //         }
    //     );
    // };

    // const onDataRetrieved = (data: LeadDetailsHistoryData) => {
        
    //     setLeadDetailsHistory(data.task.summaryList);
        
    //     const summaryList = data.task.summaryList;

    //     // const task = summaryList.tasks[summaryList.tasks.length - 1];
    //     const createClientTask = summaryList.tasks.find((task: TaskDetails) => {
    //         return (task.taskTypeDescription.toLowerCase() === 'create client' || task.taskTypeDescription.toLowerCase() === 'create matter'  ) 
    //                 && task.taskStatusDescription.toLowerCase() === 'not started';
    //     });

    //     if (createClientTask) {
    //         if (props.onCreateClient) {
    //             props.onCreateClient(true);
    //         }
    //     } else {
    //         if (props.onCreateClient) {
    //             props.onCreateClient(false);
    //         }
    //     }

    //     // const createContactTask = summaryList.tasks.find((task: TaskDetails) => {
    //     //     return task && task.outcome && task.outcome.toLowerCase() === 'not proceeding';
    //     // });

    //     // if (props.onCreateContact) {
    //     //     props.onCreateContact(createContactTask ? true : false);
    //     // }

    //     setState((prevState) => {
    //         return {
    //             ...prevState,
    //             isLoading: false
    //         };
    //     });

    //     if (props.autoScroll !== undefined && props.autoScroll === true) {
    //         setTimeout(scrollToBottom, 1000);
    //     }        
    // };

    const convertMentionInCommentToHtml = (comment: string, label?: string) => {
        let regex = /@\[.+?\]\(.+?\)/gm;
        let displayRegex = /@\[.+?\]/g;
        let idRegex = /\(.+?\)/g;
        let matches = comment.match(regex);
        // tslint:disable-next-line: no-any
        let arr: any = [];
        let output = label ? label + ': ' : '';
        
        // tslint:disable-next-line: no-unused-expression
        if (matches) {
            // tslint:disable-next-line: no-any
            matches.forEach((m: any) => {
                let id = m.match(idRegex)[0].replace('(', '').replace(')', '');
                let display = m.match(displayRegex)[0].replace('@[', '').replace(']', '');

                arr.push({ id: id, display: display });
            });

            let newComment = comment.split(regex);
            
            for (let i = 0; i < newComment.length; i++) {
                const c = newComment[i];
                if (i === newComment.length - 1) {
                    output += c;
                } else {
                    output += c + `<span class="mentioned" mentioned_id="${arr[i].id}">${arr[i].display.split('|')[0]}</span>`;
                }
            }
        } else {
            output = comment.replace(/\n/g, '<br>');
        }

        // tslint:disable-next-line: no-console
        console.log('output', output);

        return output;
    }; 

    // const stringToColour = (str: string | null) => {
    //    if (str) {
    //         var hash = 0;
    //         for (var i = 0; i < str.length; i++) {
    //         // tslint:disable-next-line: no-bitwise
    //         hash = str.charCodeAt(i) + ((hash << 5) - hash);
    //         }
    //         var colour = '#';
    //         // tslint:disable-next-line: no-duplicate-variable
    //         for (var i = 0; i < 3; i++) {
    //         // tslint:disable-next-line: no-bitwise
    //         var value = (hash >> (i * 8)) & 0xFF;
    //         colour += ('00' + value.toString(16)).substr(-2);
    //         }
    //         return colour;
    //    }
    //    return '#808080';
    // };

    const isDateDue = (value: Date) => {
        const currentDate = moment();
        const diff = moment(value).diff(currentDate, 'days');

        return diff < 0;
    };
    
    const stringToColour = (str: string | null) => {
        return Colors[(str === '' || str === null) ? Colors[Colors.length] : (hashString(str) % Colors.length) - 1];
    };

    const showDateEndOfFromNow = (value: Date | string) => {
        // const utc = moment.utc(value).format();
        // return moment.utc(value).endOf('day').fromNow();
        return moment(value).fromNow();

    };

    const formatDate = (date: Date | string, format: string) => {
        // return moment.utc(date).format(format);
        return moment(date).format(format);
    };

    // const isDateDue = (value: Date) => {
    //     const currentDate = moment();
    //     const diff = moment(value).diff(currentDate, 'days');

    //     return diff < 0;
    // };

    const getWidth = () => {
        if (props.width === HistoryActivityWidth.NORMAL) {
            return 'initial';
        } else if (props.width === HistoryActivityWidth.FULL_WIDTH) {
            return 'auto';
        } else {
            return 'initial';
        }
    };

    const LightTooltip = withStyles((theme: Theme) => ({
        arrow: {
            color: theme.palette.common.white,
            '&::before': {
                // backgroundColor: 'blue',
                boxShadow: theme.shadows[1],
            }
        },
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
    }))(Tooltip);
    
    const getHistoryAvatar = (task: TaskDetails) => {
        
        if (task.taskTypeDescription && 
            (task.taskTypeDescription.toLowerCase() === 'create client' || task.taskTypeDescription.toLowerCase() === 'create matter')) {
            return (
                <Avatar style={{backgroundColor: stringToColour(task.assignedByName)}}>
                    <PermContactCalendarOutlinedIcon />
                </Avatar> 
            );
        } else if (task.taskTypeDescription && task.taskTypeDescription.toLowerCase() === 'assign enquiry') {
            return (
                <Avatar style={{backgroundColor: stringToColour(task.assignedByName)}}>
                    <AssignmentIndOutlinedIcon />
                </Avatar> 
            );
        } else if (task.taskTypeInternalDescription && task.taskTypeInternalDescription === 'BookMeeting') {
            return (
                <Avatar style={{backgroundColor: stringToColour(task.assignedByName)}}>
                    <EventOutlinedIcon />
                </Avatar> 
            );
        } else if (task.followUpActionSystemName && task.followUpActionSystemName === 'HadMeeting') {
            return (
                <Avatar style={{backgroundColor: stringToColour(task.assignedByName)}}>
                    <SupervisorAccountOutlinedIcon />
                </Avatar> 
            );
        } else { // enquiry follow up
            if (task.followUpAction 
                && (task.followUpAction.toLowerCase() === 'email' || task.followUpAction.toLowerCase() === 'emailed' )) {
                return (
                    <Avatar style={{backgroundColor: stringToColour(task.assignedByName)}}>
                        <MailOutlinedIcon />
                    </Avatar> 
                );
            } else if (task.followUpActionSystemName && task.followUpActionSystemName.toLowerCase() === 'sendemail') {
                return (
                    <Avatar style={{backgroundColor: stringToColour(task.assignedByName)}}>
                        <DraftsOutlinedIcon />
                    </Avatar> 
                );
            } else if (task.followUpAction && 
                (task.followUpAction.toLowerCase() === 'phone call' || task.followUpAction.toLowerCase() === 'phoned' )) {
                return (
                    <Avatar style={{backgroundColor: stringToColour(task.assignedByName)}}>
                        <CallOutlinedIcon />
                    </Avatar> 
                );
            } else if (task.followUpAction && task.followUpAction.toLowerCase() === 'sms') {
                return (
                    <Avatar style={{backgroundColor: stringToColour(task.assignedByName)}}>
                        <SmsOutlinedIcon />
                    </Avatar> 
                );
            } else if (task.followUpAction && task.followUpAction.toLowerCase() === 'internal note') {
                return (
                    <Avatar style={{backgroundColor: stringToColour(task.assignedByName)}}>
                        <NotesOutlinedIcon />
                    </Avatar> 
                );
            } else if (task.followUpAction && task.followUpAction.toLowerCase() === 'book a meeting') { // meeting task
                return (
                    <Avatar style={{backgroundColor: stringToColour(task.assignedByName)}}>
                        <PeopleOutlineOutlinedIcon />
                    </Avatar> 
                );
            } else if (task.followUpAction && task.followUpAction.toLowerCase() === 're-open enquiry') {
                return (
                    <Avatar style={{backgroundColor: '#007d00'}}>
                        <CheckOutlinedIcon />
                    </Avatar> 
                );            
            } else if (task.followUpAction && task.followUpAction.toLowerCase() === 'unlink matter') {
                return (
                    <Avatar style={{backgroundColor: '#808080'}}>
                        <LinkOffOutlinedIcon />
                    </Avatar> 
                );
            
            } else { // Meeting
                return (
                    <Avatar style={{backgroundColor: stringToColour(task.assignedToName)}}>
                        <NotListedLocationOutlinedIcon />
                    </Avatar> 
                );
            }
        }
    };

    const getHistoryDate = (task: TaskDetails) => {

        // if (task.taskTypeDescription.toLocaleLowerCase() === 'book meeting') {
        //     return (
        //         <LightTooltip 
        //             arrow={true}
        //             title={formatDate(task.dueDate ? task.dueDate : task.createdDate, 'Do MMM YYYY hh:mm a')} 
        //         >
        //             <div className="date">
        //                 {task.completedDate 
        //                     ? showDateEndOfFromNow(task.dueDate ? task.dueDate : task.createdDate)
        //                     : <span className="date due-date">{showDateEndOfFromNow(task.dueDate ? task.dueDate : task.createdDate)}</span> 
        //                 }
        //             </div>
        //         </LightTooltip>
        //     );
        // } else 
        if (task.taskTypeInternalDescription === 'BookMeeting' && task.createdDate) {
            return (
                <LightTooltip 
                    arrow={true}
                    title={formatDate(task.createdDate, 'Do MMM YYYY hh:mm a')} 
                >
                    <div className="date">
                        {showDateEndOfFromNow(task.createdDate)}
                    </div>
                </LightTooltip>
            );
        }
        if (task.completedDate) {
            return (
                <LightTooltip 
                    arrow={true}
                    title={formatDate(task.completedDate, 'Do MMM YYYY hh:mm a')} 
                >
                    <div className="date">
                        {showDateEndOfFromNow(task.completedDate)}
                    </div>
                </LightTooltip>
            );
        } else if (task.dueDateUTC) {
            return (
                <LightTooltip 
                    arrow={true}
                    title={formatDate(convertToLocalTimeZone(task.dueDateUTC.toString()), 'Do MMM YYYY hh:mm a')} 
                >
                    <div className={`date ${isDateDue(convertToLocalTimeZone(task.dueDateUTC.toString())) ? 'overdue' : 'due-date'}`}>
                        {showDateEndOfFromNow(convertToLocalTimeZone(task.dueDateUTC.toString()))}
                    </div>
                </LightTooltip>
            );
        } else {
            return (
                <div className="date due-date">
                    Due
                </div>
            );
        }
    };

    const getDescription = (task: TaskDetails) => {
        let actionedDescription = task.actionedDescription && task.actionedDescription.length > 0 
            ? task.actionedDescription 
            : undefined;

        if (task.taskTypeDescription.toLowerCase() === 'enquiry follow up' || task.taskTypeDescription.toLowerCase() === 'book a meeting') {
            if (task.followUpAction && task.followUpAction.toLowerCase() === 're-open enquiry') {
                return actionedDescription ? actionedDescription : 'Re-Opened';
            } else if (task.completedDate) {
                return actionedDescription ? actionedDescription : task.followUpAction;
            } else if (task.dueDate) {
                return actionedDescription ? actionedDescription : 'Follow Up Due';
            } else {
                return actionedDescription ? actionedDescription : task.followUpAction;
            }
        } else {
            const enquiryText = task.isKeyOpportunity ? t('KeyOpportunity') : t('Enquiry');

            if (task.enquiryLinkedMatter) {
                return 'Link Matter';
            }

            let taskTypeDescription = task.taskTypeDescription.replace('Enquiry', enquiryText);

            if (taskTypeDescription === 'Create Matter' && props.enquiryDetail.isMatterCreated === false && props.enquiryDetail.isContactCreated === true && 
                props.canCreateMatter === false) {
                taskTypeDescription = 'Create Contact';
            }

            return taskTypeDescription;
        }
    };    

    const onAssign = (task: TaskDetails) => () => {
        if (props.onAssign) {
            props.onAssign(task);
        }
    };

    const getAssignedTo = (task: TaskDetails) => {

        let uiUtcOffset = new Date().getTimezoneOffset() * -1;

        let uiTimeZoneDescription = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (props.timezone) {
            let uiTimeZone = props.timezone.find((item: TimezoneOption) => {
                return item.baseUtcOffset === uiUtcOffset;
            });

            if (uiTimeZone) {
                uiTimeZoneDescription = uiTimeZone.timezoneValue;
            }
        }

        if (task.isAssignedToTeam && task.assignedToName) {
            return (
                <p className="description wordBreak">
                    {`Meeting scheduled at ${task.firmOffice} ${task.bookMeetingStartDateUTC ? `on ${moment(convertToLocalTimeZone(task.bookMeetingStartDateUTC.toString())).format('DD MMM YYYY hh:mm a')}` : ''} (${task.timezone ? uiTimeZoneDescription : ''})`}
                </p>
            );
        }

        if (!task.isAssignedToTeam && task.staff) {
            return (
                <p className="description wordBreak">
                    {'Meeting with ' + task.staff + 
                        (task.firmOffice ? (' at ' + task.firmOffice + '' ) : '' ) +
                        (task.bookMeetingStartDateUTC 
                            ? (' on ' + moment(convertToLocalTimeZone(task.bookMeetingStartDateUTC.toString())).format('DD MMM YYYY hh:mm a')  ) 
                            : '' ) +
                        (task.timezone ? ' (' + uiTimeZoneDescription + ')' : '')
                    }
                </p>
            );
        }

        return undefined;
        
    };

    const getDetailContent = (task: TaskDetails) => {

        if (task.taskTypeDescription.toLowerCase() === 'assign enquiry' && task.assignedToGuidID) {
            return (
                <Paper elevation={3} className={classes.detailsContainer}>
                    <h5 className="title">
                        Assigned to <span>{task.assignedToName}</span> by <span>{task.assignedByName}</span>
                    </h5>
                    {task.followUpNotes && (
                        <p 
                            className="description" 
                            dangerouslySetInnerHTML={{
                                __html: convertMentionInCommentToHtml(task.followUpNotes)
                            }} 
                        />
                    )}
                </Paper>
            );
        } else if (task.enquiryLinkedMatter || task.taskTypeDescription.toLowerCase() === 'link matter') {
            return (
                <Paper elevation={3} className={classes.detailsContainer}>
                    {task.ownerName && (
                        <h5 className="title">
                            {task.ownerName}
                        </h5>
                    )}
                    {task.enquiryLinkedMatter 
                        ? (
                            <h5 className="title">
                                {`${task.linkedMatterClient ? task.linkedMatterClient : '' } - ${task.linkedMatterTitle } (${task.enquiryLinkedMatter})`}
                            </h5>
                        )
                        : (
                            <p className={classes.notes}>
                                Matter synching is in progress. Please check again after a short while.
                            </p>
                        )
                    }
                </Paper>
            );
        } else if (task.taskTypeInternalDescription === 'BookMeeting') {
            return (
                <Paper elevation={3} className={classes.detailsContainer}>
                    {task.emailContent && (
                        <div className={classes.emailContentButton}>
                            <Button 
                                color="primary"
                                onClick={() => {
                                    setState((prevState) => {
                                        return {
                                            ...prevState,
                                            showEmailContent: true,
                                            taskGuid: task.taskGuidID
                                        };
                                    });
                                }}
                            >
                                Content
                            </Button>
                        </div>
                        
                    )}
                    {/* get the assign to for team and user */}
                    {getAssignedTo(task)}

                    {task.meetingNotes && (
                        <p className="description">{'Meeting Notes: ' + task.meetingNotes}</p>
                    )}
                    {task.sendConfirmation !== undefined && !task.isAssignedToTeam && (
                        <p className="description">{'Confirmation Sent: ' + (task.sendConfirmation ? 'Yes' : 'No')}</p>
                    )}
                    {task.feeApplicable && (
                        <p className="description">{'Fee Applicable: ' + props.currency + formatNumber(task.feeApplicable)}</p>
                    )}
                    {task.assignedToName && task.isAssignedToTeam && (
                        <p className="description">{`Assigned To: ${task.assignedToName}`}</p>
                    )}
                    {task.followUpNotes && (
                        <p 
                            className="description" 
                            dangerouslySetInnerHTML={{
                                __html: convertMentionInCommentToHtml(task.followUpNotes, 'Follow Up Notes')
                            }} 
                        />
                    )}
                    <div className="author">Created by: <span>{task.assignedByName}</span></div>
                    {task.canAssignMeeting && (
                        <Button 
                            variant="outlined"
                            size="small"
                            className={classes.assignButton}
                            color="primary"
                            onClick={onAssign(task)}
                        >
                            Assign
                        </Button>
                    )}
                </Paper>
            );
        } else if (task.taskTypeInternalDescription === 'MeetingTask' &&
                (task.followUpNotes || task.outcomeNotes)) {
            return (
                <Paper elevation={3} className={classes.detailsContainer}>
                    {task.assignedByName && (
                        <h5 className="title">
                            {task.assignedByName}
                        </h5>
                    )}
                    {task.followUpNotes && (
                        <p 
                            className="description" 
                            dangerouslySetInnerHTML={{
                                __html: convertMentionInCommentToHtml(task.followUpNotes)
                            }} 
                        />
                    )}
                    {task.outcomeNotes && (
                        <p className="description">
                            {'Previous Notes: ' + task.outcomeNotes +
                                (task.createdDate ? ' (' + moment(task.createdDate).format('DD MMM YYYY') + ') ' : '')
                            }
                        </p>
                    )}
                </Paper>
            );
        } else if (task.systemAction === 'CreateEmail') {
            return (
                <Paper elevation={3} className={classes.detailsContainer}>
                    {task.emailContent && (
                        <div className={classes.emailContentButton}>
                            <Button 
                                color="primary"
                                onClick={() => {
                                    setState((prevState) => {
                                        return {
                                            ...prevState,
                                            showEmailContent: true,
                                            taskGuid: task.taskGuidID
                                        };
                                    });
                                }}
                            >
                                Content
                            </Button>
                        </div>
                        
                    )}
                    <div className={classes.emailDetails}>
                        {task.emailTemplateName && (
                            <p className="emailDescription">{'Template Name: ' + task.emailTemplateName}</p>
                        )}
                        {task.emailSubject !== undefined && (
                            <p className="emailDescription">{'Subject: ' + task.emailSubject}</p>
                        )}
                        {task.fromEmailAddress && (
                            <p className="emailDescription">{'From: ' + task.fromEmailAddress}</p>
                        )}
                        {task.toEmailAddresses && (
                            <p className="emailDescription">{'To: ' + task.toEmailAddresses}</p>
                        )}
                        {task.ccEmailAddresses && (
                            <p className="emailDescription">{'Cc: ' + task.ccEmailAddresses}</p>
                        )}                    
                        <div className="author">Sent by: <span>{task.assignedByName}</span></div>
                        {task.outcomeNotes && (
                            <p className="emailDescription">
                                {'Previous Notes: ' + task.outcomeNotes +
                                    (task.createdDate ? ' (' + moment(task.createdDate).format('DD MMM YYYY') + ') ' : '')
                                }
                            </p>
                        )}
                    </div>
                </Paper>
            );
        } else if ((task.dueDate && task.completedDate) && getDescription(task) !== 'Follow Up Due') {
            return (
                <Paper elevation={3} className={classes.detailsContainer}>
                    {task.assignedByName && (
                        <h5 className="title">
                            {task.assignedByName}
                        </h5>
                    )}
                    {task.enquiryLinkedMatter && (
                            <h5 className="title">
                                Filenumber: {task.enquiryLinkedMatter}
                            </h5>
                    )                    }
                    {task.followUpNotes && (
                        <p 
                            className="description" 
                            dangerouslySetInnerHTML={{
                                __html: convertMentionInCommentToHtml(task.followUpNotes)
                            }} 
                        />
                    )}
                    {task.outcomeNotes && (
                        <p className="description">
                            {'Previous Notes: ' + task.outcomeNotes +
                                (task.createdDate ? ' (' + moment(task.createdDate).format('DD MMM YYYY') + ') ' : '')
                            }
                        </p>
                    )} 
                </Paper>
            );
        } else if (task.followUpNotes) {
            return (
                <Paper elevation={3} className={classes.detailsContainer}>
                    {task.assignedByName && (
                        <h5 className="title">
                            {task.assignedByName}
                        </h5>
                    )}
                    <p 
                        className="description" 
                        style={{ marginTop: 0 }} 
                        dangerouslySetInnerHTML={{
                            __html: convertMentionInCommentToHtml(task.followUpNotes)
                        }} 
                    />
                </Paper>
            );
        } else if (getDescription(task) === 'Follow Up Due' && task.outcomeNotes) {
            return (
                <Paper elevation={3} className={classes.detailsContainer}>     
                    {task.assignedByName && (
                        <h5 className="title">
                            {task.assignedByName}
                        </h5>
                    )}               
                    {task.outcomeNotes && (
                        <p className="description">{task.outcomeNotes}</p>
                    )}                                      
                </Paper>
            );
        } else if (task.assignedByName && task.completedDate) {
            return (
                <Paper elevation={3} className={classes.detailsContainer}>
                    {task.assignedByName && (
                        <h5 className="title">
                            {task.assignedByName}
                        </h5>
                    )}
                </Paper>
            );
        } else if (task.taskUpdateInProgress && task.isIntegrationFailed === false) {
            return (
                <Paper elevation={3} className={classes.detailsContainer}>
                    {task.assignedByName && (
                        <h5 className="due-date">
                            In Progress
                        </h5>
                    )}
                </Paper>
            );
        } else if (task.isIntegrationFailed) {
            return (
                <Paper elevation={3} className={classes.detailsContainer}>
                    {task.assignedByName && (
                        <>
                            <h5 className="title">
                                There was an issue with the integration. 
                                The following items marked with the tick have been created. 
                                Please try again by selecting the Create {props.canCreateMatter ? 'Matter' : 'Client'} button. 
                                Alternatively, if you have created the matter already, 
                                select the Mark as {props.canCreateMatter ? 'Matter' : 'Client'} Created button to link the matter to the enquiry.
                            </h5>
                            <ListItem>                               
                                <ListItemText primary="Contact Created/ Updated" />
                                <ListItemIcon>
                                    {props.enquiryDetail.integrationContactId 
                                        ? <CheckCircleIcon style={{ color: green[500] }} />
                                        : <RemoveCircleOutlinedIcon />
                                    }                                    
                                </ListItemIcon>
                            </ListItem>
                            <ListItem>                               
                                <ListItemText primary="Client Created/ Updated" />
                                <ListItemIcon>
                                    {props.enquiryDetail.integrationClientId 
                                        ? <CheckCircleIcon style={{ color: green[500] }} />
                                        : <RemoveCircleOutlinedIcon />
                                    }                                    
                                </ListItemIcon>
                            </ListItem>
                            {props.enquiryDetail.otherPartyFirstName && props.enquiryDetail.otherPartyLastName && 
                                <ListItem>                               
                                    <ListItemText primary="Other Party Created" />
                                    <ListItemIcon>
                                        {props.enquiryDetail.integrationOtherPartyId
                                            ? <CheckCircleIcon style={{ color: green[500] }} />
                                            : <RemoveCircleOutlinedIcon />
                                        }                                    
                                    </ListItemIcon>
                                </ListItem>
                            }
                            {props.canCreateMatter &&
                                <>                               
                                    <ListItem>                               
                                        <ListItemText primary="Matter Created" />
                                        <ListItemIcon>
                                            {props.enquiryDetail.integrationMatterId 
                                                ? <CheckCircleIcon style={{ color: green[500] }} />
                                                : <RemoveCircleOutlinedIcon />
                                            }                                    
                                        </ListItemIcon>
                                    </ListItem>
                                    <ListItem>                               
                                        <ListItemText primary="Matter Document Created" />
                                        <ListItemIcon>
                                            {props.enquiryDetail.integrationMatterDocumentId 
                                                ? <CheckCircleIcon style={{ color: green[500] }} />
                                                : <RemoveCircleOutlinedIcon />
                                            }                                    
                                        </ListItemIcon>
                                    </ListItem>
                                </>
                            }
                        </>                        
                    )}
                </Paper>
            );
        } else {
            return undefined;
        }
    };

    const activityContent = (task: TaskDetails) => {
        const showDetails  = task.outcome && task.outcome.toLowerCase() === 'not proceeding' ? false : true;
        
        return (
            <>
                {props.sortDirection === 'DESC' && task.outcome && task.outcome.toLowerCase() === 'not proceeding' && notProceeding(task)}

                {showDetails === true && (
                    <div className={classes.historyContainer}>
                        <div className="connector" />
                        <div className={classes.historyIcon}>
                            {getHistoryAvatar(task)}
                        </div>
                        <div className={classes.taskDetails}>
                            <div className="description">
                                {getDescription(task)}
                            </div>
                            {getHistoryDate(task)}
                        </div>
                        <div className={classes.contentDetails} style={{ flex: getWidth() }}>
                            {getDetailContent(task)}
                        </div>
                    </div>
                )}

                {props.sortDirection === 'ASC' && task.outcome && task.outcome.toLowerCase() === 'not proceeding' && notProceeding(task)}
            </>
        );
    };

    const enquiryCreated = () => {
        return (
            <div className={classes.historyContainer}>
                <div className="connector" />
                <div className={classes.historyIcon}>
                    <Avatar style={{backgroundColor: '#007d00'}}>
                        <CheckOutlinedIcon />
                    </Avatar> 
                </div>
                <div className={classes.taskDetails}>
                    <div className="description">
                        Created
                    </div>
                    {props.enquiryDetail && props.enquiryDetail.createdDate && (
                        <LightTooltip 
                            arrow={true}
                            title={formatDate(props.enquiryDetail.createdDate, 'Do MMM YYYY hh:mm a')} 
                        >
                            <div className="date">
                                {showDateEndOfFromNow(props.enquiryDetail.createdDate)}
                            </div>
                        </LightTooltip>                        
                    )}
                </div>
                <div className={classes.contentDetails} style={{ flex: getWidth() }}>
                    <Paper elevation={3} className={classes.detailsContainer}>
                        {props.enquiryDetail.areaOfLaw && (
                            <h5 className="title">
                                {props.enquiryDetail.source 
                                    ? props.enquiryDetail.areaOfLaw + ' (' + props.enquiryDetail.source.trim() + ')'
                                    : props.enquiryDetail.areaOfLaw
                                }
                            </h5>
                        )}
                        {props.enquiryDetail.natureOfEnquiry && (
                            <p className="description">{props.enquiryDetail.natureOfEnquiry}</p>
                        )}
                        {/* TODO: Show Created by as well. We don't have CreatedBy Value yet */}
                        <div className="author">Created by: <span>{props.enquiryDetail.createdByName}</span></div>
                    </Paper>
                </div>
            </div>
        );
    };

    const notProceeding = (task: TaskDetails) => {
        return (
            <div className={classes.historyContainer}>
                <div className="connector" />
                <div className={classes.historyIcon}>
                    <Avatar style={{backgroundColor: 'grey'}}>
                        <NotInterestedOutlinedIcon />
                    </Avatar> 
                </div>
                <div className={classes.taskDetails}>
                    <div className="description">
                        Not Proceeding
                    </div>
                    {task.updatedDate && (
                        <LightTooltip 
                            arrow={true}
                            title={formatDate(task.updatedDate, 'Do MMM YYYY hh:mm a')} 
                        >
                            <div className="date">
                                {showDateEndOfFromNow(task.updatedDate)}
                            </div>
                        </LightTooltip>
                    
                    )}
                </div>
                <div className={classes.contentDetails} style={{ flex: getWidth() }}>
                    <Paper elevation={3} className={classes.detailsContainer}>
                        {task.assignedByName && (
                            <h5 className="title">{task.assignedByName}</h5>
                        )}
                        {task.followUpNotes && (
                            <p 
                                className="description" 
                                dangerouslySetInnerHTML={{
                                    __html: convertMentionInCommentToHtml(task.followUpNotes)
                                }} 
                            />
                        )}
                        {task.reason && (
                            <p className="description">{task.reason}</p>
                        )}                       
                        {task.referredToFirmName && (
                            <p className="description">Referred To: {task.referredToFirmName}</p>
                        )}
                        {task.notProceedingNotes && (
                            <p className="description">{task.notProceedingNotes}</p>
                        )}
                    </Paper>
                </div>
            </div>
        );
    };

    const taskList = JSON.parse(JSON.stringify(props.leadDetailsHistory.tasks));

    const taskCreateClient = taskList.find((task: TaskDetails) => {
        return (task.taskTypeDescription.toLowerCase() === 'create client' || task.taskTypeDescription.toLowerCase() === 'create matter') 
            && task.taskStatusDescription.toLowerCase() === 'not started';
    });

    let newTasks = taskList;

    if (taskCreateClient) {
        const index = taskList.indexOf(taskCreateClient);
        newTasks.splice(index, 1);
    }

    const onCloseEmailContent = () => {
        setState((prevState) => {
            return {
                ...prevState,
                showEmailContent: false,
                taskGuid: undefined
            };
        });
    };

    // tslint:disable-next-line: no-any
    // const convertToTimeZoneByOffSet = (date: Date | null, offset: any) => {
    //     const momentDate = moment(date);
    //     let uiDate = null;
    //     if (momentDate.isValid() && date) {
    //         uiDate = momentDate.utc().add(offset, 'minutes').toDate();
    //     }
    //     return uiDate;
    // };   
    
    const convertToLocalTimeZone = (date: string) => {
        var oldDate = new Date(date);
        var newDate = new Date(date);
        newDate.setMinutes(oldDate.getMinutes() - oldDate.getTimezoneOffset());
        // console.log('newDate', newDate);
        return newDate;
    }; 

    // if (state.isLoading) {
    //     return (
    //         <div className={`${classes.loadingRoot} ${props.className}`}>
    //             <RvLoader />
    //         </div>
    //     );
    // }

    if (props.autoScroll !== undefined && props.autoScroll === true) {
        setTimeout(scrollToBottom, 1000);
    }  

    return (
        <div className={`${classes.root} ${props.className}`}>
            {
                (state.showEmailContent && state.taskGuid) && (
                    <EmailContentDialog
                        onCloseEmailContent={onCloseEmailContent}
                        taskGuid={state.taskGuid}
                    />
                    // <Dialog
                    //     open={state.showEmailContent}
                    //     onClose={onCloseEmailContent}
                    //     maxWidth="md"
                    //     fullWidth={true}
                    //     className={classes.dialogRoot}
                    //     scroll={'paper'}
                    //     classes={{
                    //         paper: classes.dialogPaper
                    //     }}                        
                    // >
                    //     <DialogTitle className={classes.header}>
                    //         <span>Email Content</span>
                    //         <Button onClick={onCloseEmailContent} color="primary">
                    //             Close
                    //         </Button>
                    //     </DialogTitle>
                    //     <DialogContent dividers={true}>
                    //         <>
                    //             <Typography variant="h6">{'Attachments'}</Typography> 
                    //             {getFileItems(state.files)}
                    //             <Divider className={classes.divider} />
                    //             <div
                    //                 dangerouslySetInnerHTML={{
                    //                     __html: state.emailContent
                    //                 }}
                    //             />
                    //         </>                                                        
                    //     </DialogContent>                        
                    // </Dialog>
                )
            }
            {props.sortDirection === 'ASC' && enquiryCreated()}
            
            {newTasks.map((task: TaskDetails) => activityContent(task))}

            {taskCreateClient && 
                activityContent(taskCreateClient)
            }

            {props.sortDirection === 'DESC' && enquiryCreated()}
            <div ref={messagesEndRef} />
        </div>
    );
};
